import '@/css/app.pcss';


// For the on Doc ready: Define a convenience method and use it
let ready = (callback) => {
  if (document.readyState != "loading") callback();
  else document.addEventListener("DOMContentLoaded", callback);
}

ready(() => {
  /* Do things after DOM has fully loaded */
  console.log('Doc is ready!');
  
}); // END DOC READY

// Get references to the elements
const wrapper = document.getElementById('hamburger-wrapper')
const mobileNav = document.querySelector('.mobile-menu-links');

// Function to toggle the mobile navigation
function toggleMobileNav() {
  mobileNav.classList.toggle('open');
}

// Add click event listener to the hamburger button
wrapper.addEventListener('click', toggleMobileNav);
wrapper.addEventListener("click", () => {
  wrapper.classList.toggle("open")
})
// Close mobile navigation when clicking outside the menu
document.addEventListener('click', function (event) {
  if (!mobileNav.contains(event.target) && !wrapper.contains(event.target)) {
    mobileNav.classList.remove('open');
  }
});


document.addEventListener('DOMContentLoaded', function() {
  const content = document.getElementById('content-slide');

  // Function to slide the content to the left
  function slideToLeft() {
    content.style.opacity = '1';
  }

  // Call the slideToLeft function after a delay (e.g., 1 second)
  setTimeout(slideToLeft, 0);
});

